.associations
    display: flex
    justify-content: center
    align-items: center
    flex-direction: column
    .associations__description
        text-align: center
        color: #666
        font-size: 14
.brand__section
    margin: 4rem 0
    padding: 3rem 0
    background-color: #f8f8f8
    .brand
        display: grid
        grid-template-columns: repeat(5, 1fr)
        background-color: #f8f8f8
        grid-column-gap: 1px
        grid-row-gap: 1px
        @media screen and ( max-width: 950px )
            grid-template-columns: repeat(3, 1fr)

            
        
        .brand__card
            background-color: #ffffff

            .brand__image
                max-width: 100%
                height: auto
                aspect-ratio: 3/2
                object-fit: contain

.other-associations-section
    padding-bottom: 6rem
    .brand
        display: grid
        background-color: #f8f8f8
        grid-column-gap: 1px
        grid-row-gap: 1px
        border: 1px solid #f8f8f8
        grid-template-columns: repeat(5, 1fr)
        @media screen and ( max-width: 950px )
            grid-template-columns: repeat(3, 1fr)
        .brand__card
            background-color: #ffffff
            padding: 2rem 3rem
            .brand__image
                max-width: 100%
                height: auto
                aspect-ratio: 3/2
                object-fit: contain
                

