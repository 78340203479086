.scroll-btn
    display: none
    justify-content: center
    align-items: center
    color: #fff
    position: fixed
    z-index: 999
    cursor: pointer
    bottom: 30px
    right: 30px
    .icon
        display: flex
        justify-content: center
        align-items: center
        width: 40px
        height: 40px
        background-color: #dd3333
        border-radius: 4px

        i
            font-size: 30px
.visible
    display: block