.solutions-detail
    & ul
        padding-left: 0
        margin-bottom: 0
    & p
        padding: 0
        line-height: 1.7
        letter-spacing: 0
        margin: 0 0 20px
        color: #777 
        font-size: 14px 
        line-height: 24px  
    .left
        .left__menu
            .left__item
                margin-bottom: 1rem
                list-style: none
             
                a
                    background-color: #f5f2f2
                    font-weight: 600
                    color: #242424
                    padding: 14px 20px
                    display: block
                    text-transform: uppercase
                    transition: 0.3s ease
                    &:hover,
                    &.active
                        background-color: #dd3333
                        color: #fff
    .right
        .right__image
            max-width: 100%
            height: auto
        .right__title
            display: flex
            justify-content: space-between
            margin: 2rem 0

            @media screen and (max-width: 568px)
                flex-direction: column

            .download
                margin-top: 20px
                a
                    padding: 10px 20px 
                    border: 2px solid #dd3333
                    color: #333
                    font-weight: 600
                    position: relative
                    margin: 0 5px
                    i
                        padding-right: 5px

        .right__details
           

  
      
            
       
        
        
        
        
    .accordion-button::after 
        width: 0
        
        
    .accordion-header i
                background-color: #dd3333
                width: 45px
                height: 45px
                line-height: 45px
                text-align: center
                position: absolute
                left: 0
                top: 50%
                color:#fff
                transform: translateY(-50%)
    .accordion-header span
        padding-left: 3rem
  

            
    .accordion-item
        padding-bottom: 1rem
        
    .accordion-body li
        list-style-type: disc
        font-size: 14px
        
    .accordion-body
        padding-left: 4rem