.custom-wrapper
    .left
        .left__image
            max-width: 100%
            height: auto
            clip-path: polygon(100% 0%, 100% 50%, 100% 100%, 0 100%, 0 21%, 21% 0)
    .right
        padding: 0 30px
.accordion-item
    border: none
    &:hover
        .accordion-button
            color: #dd3333
             
.accordion-body
    color: #555
    font-size: 14px
    line-height: 24px

.accordion-button
    font-size: 16px
    font-weight: 700
  
.accordion-button:focus
    box-shadow: none
    border-color: none     
           
.faqs-bg-image
    width: 100%
    height: 70vh
    background-size: cover
    background-position: center
    background-repeat: no-repeat
    clip-path: polygon(100% 0%, 100% 86%, 86% 100%, 0 100%, 0 0, 21% 0)
    position: relative
    display: flex
    justify-content: center
    align-items: center
    margin: 2rem 0
    padding: 2rem 0
    &::before
      content: ""
      position: absolute
      width: 100%
      height: 100%
      top:0
      left:0
      background-color: rgba(20, 21, 30, 0.8)
    .content
        font-size: 30px
        z-index: 1
        font-weight: 800
        color: #eee
        display: flex
        justify-content: center
        align-items: center
        position: relative
        text-align: center
        p
            width: 70%
        @media (max-width: 990px)
            font-size: 22px
        @media (max-width: 430px)
            font-size: 18px
