.blog__card
        .blog__link
          color: #d24e1a
        .blog__img
          width: 100%
          height: 250px
          background-size: cover
          background-position: center
          background-repeat: no-repeat
          position: relative
          
          .time
            position: absolute
            z-index: 15
            padding: 19px 18px 22px
            max-width: 100%
            width: 80px
            &::before
              position: absolute
              content: ""
              left: 0
              bottom: 1px
              border-top: 15px solid #d24e1a
              border-right: 80px solid transparent
              z-index: -1
            &::after
              position: absolute
              content: ""
              top: 0
              left: 0
              bottom: 15px
              right: 0
              background-color: #d24e1a
              z-index: -1
 
                      
            h4
              font-size: 24px
              color: #fff
              font-weight: 300
              position: relative
              &::after
                content: ""
                position: absolute
                left: 0
                bottom:-4px
                width: 30px
                height: 1px
                background-color: #fbff01
            h5
              font-size: 14px 
              color:#fff
              line-height: 14px

        .blog__title--section
          padding-top:1rem
          .title-header
            margin-bottom: .5rem
          .blog__title
            font-size: 24px
            line-height: 24px
            position: relative
            color: #1e293b
            font-weight: 500
            transition: 0.3s ease
            &:hover
              color: $primary-color
     
      