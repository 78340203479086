
.contact
  padding-bottom: 7rem
  .contact__form--sec
  
    background-color: #16161b
    height: 100%


    h3
      color: #eee
      text-align: center
      padding: 1rem 0
      text-transform: capitalize
      background-color: #111724

  
    .contact__form
      padding: 30px 
      display: flex 
      justify-content: center
      flex-direction: column
      .form-group
        margin: 1rem 0
        input
          width: 100%
          padding: 1rem .5rem
          background-color: #191a1f
          color: #eee
          font-size: 14px
          border: 1px solid #b9b7b7
        textarea
          width: 100%
          padding: 1rem .5rem
          background-color: #191a1f
          color: #eee
          font-size: 14px
          border: 1px solid #b9b7b7
      button
        padding: .5rem 1rem 
        background-color:#dd3333 !important
        font-weight: 400
        font-size: 14px
        color: #fff
        text-transform: capitalize
        transition: 0.3s ease
        border: 1px solid #FFF
        height: 40px

        .spinner-border 
          height: 1rem
          width: 1rem
          border-width: 1px

        &:hover
          background-color: #16161b !important
  .contact__info--sec
    background-color: #111724
    position: relative
 
    h3
      color: #eee
      text-align: start
      font-size: 22px
      padding: 1rem 0
      padding-left: 2rem
      text-transform: capitalize
      background-color: #111724

    .contact__info
      background-color: #16161b
      padding: 30px
      .info
          display: flex
          align-items:center
          gap: 1rem
          color: #eee
          font-size:14px 
          max-width: 70%
          i
            font-size: 18px
            color: #dd3333
  .map-holder
    width: 100%
    height: 320px
    border-radius: 8px
    iframe
      width: 100%
      height: 100%



