.product-detail
    margin-bottom: 2rem
   
    .product__content
        .product__description
            font-size: 16px
            color: #666
            line-height: 24px
            text-align: justify

            img 
                max-width: 100%
                max-height: 100%
                object-fit: contain

            ul
                li
                    list-style-type: disc
                    margin-bottom: 10px
        .download
            span
                font-size: 13px
                font-style: italic
            h6
                font-size: 13px
                padding-top: 10px

    .product__image
        max-width: 100%
        height: auto       