.custom-card
  display: grid
  grid-template-columns: 1fr 1fr
  border: none
  margin: 0 0 30px
  @media ( max-width: 768px  )
    grid-template-columns: 1fr

    
  .custom-card__image
    width: 100%
    height: 300px
    object-fit: contain
    background-color: #ffffff
  
  .custom-card__info
    background-color: #181e2b
    padding: 0 30px
    display: flex
    justify-content: center
    flex-direction: column
    @media ( max-width: 768px  )
      padding: 30px 
    .custom-card__title
      font-size: 24px
      font-weight: 700
      color: #eee
      transition: 0.3s ease
      text-transform: capitalize
      &:hover
        color: #dd3333
    .custom-card__summary
      margin: 15px 0
      font-size: 14px
      line-height: 24px
      color: #999
      position: relative
      &::after
        content: ""
        position: absolute
        bottom: -30px
        left: 0
        width: 100%
        height: 1px
        background-color: #979797
    .custom-card__button
      margin-top: 30px
      a
        font-size: 18px
        text-transform: capitalize
        color: #eee
        transition: 0.3s ease
        &:hover
          color: $primary-color
  &:last-child
    margin-bottom: 0      

