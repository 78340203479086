.tas
    @media screen and (max-width: 568px)
        .g-5, .gx-5 
            --bs-gutter-x: 0

    .tas__image
        max-width: 100%
        height: auto
    .tas__content
        .tas__description
            color: #666
            font-size: 14px
            line-height: 24px
            text-align: justify


.items
    margin: 4rem 0
    .items__item
        border: 1px solid #e7e7e7
        padding: 1.5rem
        position: relative
        height: 100%
        
                          
       
            left: 0
            bottom: 0
            content: ""
            width: 0
            height: 2px
            position: absolute
            transition: all 0.2s linear
            background: $primary-color
            transition-delay: 0.2s
        .items__content
            .items__description
                color:#666
                font-size: 14px
                line-height: 24px


                   

                    
        
                

            
        .items__content
           
            h4
                text-transform: capitalize
                position: relative
                z-index: 1
                line-height: 100px
                font-weight: 700
                span
                    position: absolute
                    left: 0
                    top: 0
                    font-size: 90px
                    z-index: -1
                    color: #eef1f6
                    opacity: 0.5
                    font-weight: 900
            .items__description
                color:#666
                    font-size: 14px              

   

.testimonials
    padding: 3rem 0
    background-size: cover
    background-repeat: no-repeat
    background-position: center
    position: relative
    width: 100%
    height: 100%
  



