
 
.blog  
 
 
  .blog__bg--image
    width: 100%
    min-height: 500px
    background-size: cover
    background-position: center
    background-repeat: no-repeat
    position: relative


    &::before
      content: ""
      position: absolute
      width: 100%
      height: 100%
      background-color: rgba(#f8feff, 0.9)
    
    

 

   
    .blog__list
      padding: 3rem 0
      z-index: 1
      position: relative
    
      
      



