.about
  .about__content
  .about__image
    margin-bottom: 2rem
    z-index: 10
    position: relative
    max-width: 100%
    height: auto 
      
     
  .about__content
    padding:  2rem 0
    display: flex
    justify-content: center
    align-items: start
    flex-direction: column

    .about__text
      font-size: 14px
      color: #666
      line-height: 24px
       
     
    
