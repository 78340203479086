.services
    padding: 0rem 0 8rem
    .services__card
        background-color: #efefef

        border-radius: 5px
       
        .services__image
            width: 100%
            height: 230px
            object-fit: cover
            border-radius: 5px 0 0 5px

        .services__content
            padding: 1rem
            & p
                margin-bottom: 0px



            .services__title
                font-size: 24px
                text-transform: capitalize
            .services__details
                font-size: 14px
                color: #666   
                   
