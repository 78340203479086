.custom-button
  padding: 0.6rem 1rem
  background-color: $primary-color
  color: #fff
  font-size: 16px
  text-transform: capitalize
  margin: 1rem 0
  transition: 0.3s ease
  display: inline-block
  &:hover
    background-color: $secondary-color
