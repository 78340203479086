.areas-of-application
    background-color: #f8f8f8
    margin-bottom: 3rem
    padding: 4rem 0
  
    .item
        display: flex
        flex-direction: column
        align-items: center
        padding-top: 1rem
        .item__backcolor
            display: flex
            justify-content: center
            align-items: center
            width: 90px
            height: 90px
            background-color: #dd3333
            border-radius: 50%
        .item__title
            font-size: 18px
            font-weight: 600
            text-transform: capitalize
            padding-top: 1rem
            text-align: center
   
