.overview
  .overview__bg--image
    width: 100%
    min-height: 490px
    background-position: center
    background-size: cover
    background-repeat: no-repeat
    position: relative
    display: flex
    justify-content: start
    align-items: center
    padding: 2rem 0
    &::before
      content: ""
      position: absolute
      width: 100%
      height: 100%
      background-color: rgba(20, 21, 30, 0.9)

    .overview__content
      color: #fff  
      z-index: 1
      position: relative
      display: flex
      align-items: center
      @media screen and ( max-width:576px  )
        flex-direction: column

      .custom-wrapper
        width: 800px
        margin-right: 8rem
        @media screen and ( max-width:790px  )
          width: 700px


        @media screen and (max-width: 568px)
          width: 100%
          margin-right: 0
          text-align: center
        
        .overview__subtitle
          color: #dd3333
          font-size: 15px
          font-weight: 600
          @media screen and ( max-width:560px  )
            font-size: 13px

        .overview__title
          font-size: 50px
          font-weight: 700

          @media screen and ( max-width:790px  )
            font-size: 32px
          @media screen and ( max-width:560px  )
            font-size: 22px  
            
        .overview__samary
          font-size: 14px   
          line-height: 2


      
      
