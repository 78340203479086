@keyframes categoryAnimation
   0%
      transform: translateY(40px)
              
   100%
     transform: translateY(0)

.banner
  .banner__image
    width: 100%
    height: 100vh
    background-size: cover
    background-position: center
    background-repeat: no-repeat
    position: relative
   
      
    .banner__title--holder
      position: absolute
      top: 50%
      right: 0
      margin-top: -235px
      width: 480px
      height: 470px
      background-color: hsla(0,0%,5%,.9)
      padding: 40px
      display: flex
      justify-content: center
      align-items: center
      z-index: 10
      @media screen and ( max-width: 500px )
        width: 350px
        height: 340px
        


      .title__holder--content
        .category
          text-transform: uppercase
          color: #fff
              

        .banner__title
          padding: 0
          font-size: 36px
          letter-spacing: 0
          line-height: 1.2
          font-weight: 400
          margin: 0 0 34px
          color: #fff
          text-transform: capitalize
        .banner__description
          color: #aaa
          font-size: 14px
          padding: 0
          margin: 0
  .swiper-button-prev
    left: auto
    margin-left: 0
    right: 440px
    margin-top: 205px
    

   
    &::after
      font-size: 16px
      color: #fff
      padding: 1.5rem 
      background-color: $primary-color

    @media screen and (max-width: 568px)
      display: none
 

  .swiper-button-next
    right: 378px
    margin-top: 205px
    &::after
      font-size: 16px
      color:#fff
      padding: 1.5rem
      background-color: $primary-color

    @media screen and (max-width: 568px)
      display: none
  .swiper-slide-active
    .banner__title--holder
      .title__holder--content
        .category
            opacity: 1
            animation-fill-mode: both
            animation-iteration-count: 1
            animation-duration: 1s
            animation-delay: 0.3s
            animation-name: categoryAnimation
        .banner__title
            opacity: 1
            animation-fill-mode: both
            animation-iteration-count: 1
            animation-duration: 1s
            animation-delay: 0.4s
            animation-name: categoryAnimation
        .banner__description
            opacity: 1
            animation-fill-mode: both
            animation-iteration-count: 1
            animation-duration: 1s
            animation-delay: 0.5s
            animation-name: categoryAnimation

 



    