
.leftside
  .leftside__img
    width: 100%
    height: auto
    padding-bottom: 1rem
  .leftside__description--holder
    h5
      font-size: 18px
      line-height: 24px
    & p
      padding: 0
      line-height: 1.7
      letter-spacing: 0
      margin: 0 0 20px
      color: #666
      font-size: 14px

    ol
      li
        list-style-type: decimal
    ul
      
      li
        list-style: disc outside
        margin-bottom: 5px
        color: #777
    & blockquote
      border-left: 3px solid #6fbf71
      font-style: italic
      margin: 10px 0 23px
      padding: 19px 20px 20px
      width: 100%
      background-color: #e9eff4
      line-height: 1.7
      color: #777
      font-size: 18px
      font-family: Lora
      font-weight: 400
      border-radius: 5px

.rightside
  background-color: #111724
  padding: 1rem
  h2
    color: #eee
    text-transform: capitalize
    padding-bottom: 1rem
    font-size: 24px
  .customcard
    border-bottom: 1px solid #979797
    margin-bottom: 2rem
    .customcard__content
      display: flex
      justify-content: space-between
      align-items: center
      gap: 20px
      margin-bottom: 1rem
      .customcard__image
        width: 60px
        height: 60px
        object-fit: cover
        border-radius: 4px
      .customcard__title
        & a
          color: #eee
        p
          font-size: 14px
          margin-bottom: 0
          transition: 0.3s ease
          &:hover
            a
              color: #dd3333
            
         
      .date
        color: #dd3333
        font-size: 14px
        i
          padding-right:8px
    &:last-child
       border-bottom: 0   
