.custom-breadcrumb
  margin-bottom: 2rem   
  z-index: 0
  width: 100%
  padding: 2rem 2rem 10rem
  background-size: cover
  background-repeat: no-repeat
  background-position: center
  position: relative

  @media screen and (max-width: 568px)
    padding-left: 1rem
    padding-right: 1rem

.custom-breadcrumb::before
      position: absolute
      content: ''
      background: #fff
      width: 70%
      height: 166px
      left: 0px
      bottom: 0px
      clip-path: polygon(0% 50%, 100% 0%, 100% 100%, 0% 100%, 0% 0%)
      z-index: 1

      @media screen and (max-width: 568px)
        clip-path: polygon(0% 30%, 100% 0%, 100% 100%, 0% 100%, 0% 0%)

.custom-breadcrumb::after
      position: absolute
      content: ''
      background: #fff
      width: calc(30% + 1px)
      height: 166px
      right: 0px
      bottom: 0px
      clip-path: polygon(0% 0%, 200% 100%, 100% 100%, 0% 100%, 0% 0%)

      @media screen and (max-width: 568px)
        clip-path: polygon(0% 0%, 300% 100%, 100% 100%, 0% 100%, 0% 0%)

      
  

.bg-overlay::before 
  position: absolute
  content: ""
  background: #2d2727
  width: 100%
  height: 100%
  left: 0px
  top: 0px
  opacity: 0.7
  z-index: 0

.bd-content 
  position: relative
  padding: 3rem
  z-index: 5

  ul
    padding-left: 0

  @media screen and (max-width: 568px)
    padding: 1rem 0
    padding-top: 3rem

.bd-content .bd-title 
  font-family: var(--font-base)
  font-size: 60px
  line-height: 65px
  color: #fff
  font-weight: 700
  text-transform: capitalize

  @media screen and (max-width: 568px)
    h1
      font-size: 24px
  

.bd-content ul li,
.bd-content ul li a 
  display: inline-block
  position: relative
  padding-right: 10px
  margin-right: 10px
  font-family: var(--font-alt)
  color: #d9d1ce
  font-size: 18px
  z-index: 5

  @media screen and (max-width: 568px)
    font-size: 16px

.breadcrumb__overlay 
  position: absolute
  bottom: 0
  background: white
  left: 0px
  height: 20px
  width: 100%

.bd-content ul li::before 
  position: absolute
  content: "/"
  top: 0
  left: 80%

.bd-content ul li:last-child:before 
  position: absolute
  content: ""

.pattern-layer::before 
  position: absolute
  content: ""
  width: 70%
  height: 166px
  left: 0px
  bottom: 0px
  background-color: #dd3333
  clip-path: polygon(0% 10%, 100% 0%, 100% 0%, 0% 100%, 0% 0%)

.pattern-layer::after 
  position: absolute
  content: ""
  width: 30%
  height: 166px
  right: 0px
  bottom: 0px
  background-color: #dd3333
  clip-path: polygon(0% 0%, 100% 10%, 100% 100%, 100% 100%, 0% 0%)

  @media screen and (max-width: 568px)
    head