
.project 
  .project__content 
    background: #F8F8F8
    .project__left--content
      padding: 30px
      position: sticky
      top: 60px 
      height: 500px
      margin-bottom: 30px
      display:  flex
      justify-content: center
      align-items: center
      

      .project__left__sticky--section
     
     
        p
          font-size: 14px 
          color: #666
          margin: 25px 0 
          line-height: 24px 
     
    .project__right--content 
      color: #eee 
      background-color: $secondary-color
      padding: 30px
